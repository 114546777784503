html,
body {
  background-color: #fffffc;
  color: #30343f;
  font-family: "Work Sans", sans-serif;
  margin: 0px;
}

nav {
  background-color: #086788;
  color: #fffffc;
}

#after-nav {
  padding: 20px;
}

h1 {
  text-align: center;
}

input[type="text"],
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

footer > a {
  color: #fffffc;
}

.content {
  min-height: calc(100vh - 50px);
}

.content-inside {
  padding: 20px;
  padding-bottom: 50px;
}

footer > a:hover {
  color: #d7dae0;
}

#page-content {
  flex: 1 0 auto;

  padding-top: 10px;
}

footer {
  background-color: #086788;
}

#sticky-footer {
  flex-shrink: none;
  bottom: 0px;
}

#toplink {
  float: right;
}

@media only screen and (max-width: 500px) {
  #toplink {
    color: red;
    float: center;
  }
}

.link {
  color: #fffffc;
}

/*# sourceMappingURL=style.css.map */

.centered {
  text-align: center;
}

#form-contain {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 20px;
  padding-bottom: 50px;
}

.card {
  display: inline-block;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
  filter: blur(px);
  color: white;
  transition: transform 0.5s;
  cursor: pointer;
}

.card:hover {
  transform: scale(1.05);
}

.jumbotron {
  padding-top: 25px;
  padding-bottom: 25px;
}

.card-body {
  height: 250px;
}

#edu {
  background-image: url("./images/sc_roof.JPG");
  background-size: cover;
}

#exp {
  background-image: url("./images/code.jpeg");
  background-size: cover;
}

#acts-card {
  background-image: url("./images/me_in_mountains.JPG");
  background-size: cover;
}

#content {
  margin-top: 70px;
}

@media only screen and (max-width: 835px) {
  .mobileShow {
    display: inline;
  }
}

html {
  scroll-behavior: smooth;
}

/* 2. Make nav sticky */
main > nav {
  position: sticky;
  top: 2rem;
  align-self: start;
}

/* 3. ScrollSpy active styles (see JS tab for activation) */
.section-nav li.active > a {
  color: red;
  font-weight: 500;
}

/* Sidebar Navigation */
.section-nav {
  padding-left: 0px;
  padding-right: 15px;
  border-right: 1px solid #efefef;
  background-color: white;
  border-top: 25px solid;
  font-size: larger;
  width: fit-content;
}

.nav-item-visible {
  color: black;
  font-weight: bold;
  font-size: x-large;
}

.nav-item-hidden {
  color: rgb(66, 62, 62);
}

.section-nav a {
  text-decoration: none;
  display: block;
  padding: 0.125rem 0;
  transition: all 50ms ease-in-out; /* 💡 This small transition makes setting of the active state smooth */
}

.section-nav a:hover,
.section-nav a:focus {
  color: rgb(151, 150, 150);
}

/** Poor man's reset **/
* {
  box-sizing: border-box;
}

html,
body {
  background: #fff;
}

ul,
ol {
  margin: 0;
  padding: 0;
}
ul > li {
  margin-left: 10px;
}

h1 {
  font-weight: 300;
}

/** page layout **/
main {
  display: grid;
  grid-template-columns: 0.15fr 0.85fr;
  max-width: 100em;
  width: 90%;
  margin: 0 auto;
  padding-top: 30px;
}

/** enlarge the sections for this demo, so that we have a long scrollable page **/
section {
  padding-bottom: 1rem;
}

#resume_content {
  border-top: 1px solid white;
  padding-left: 20px;
}

iframe {
  overflow: auto;
}

img {
  display: block;
}

.content {
  padding-left: 20px;
  margin-bottom: 0px;
}

.im-right {
  float: right;
  margin-left: 30px;
  max-width: 50%;
}

.im-left {
  float: left;
  margin-right: 30px;
  max-width: 50%;
}
